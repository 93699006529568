<template>
  <div>
    <Header></Header>
    <div class="notfound">
      <div class="notfound-img">
        <img src="@/assets/banner/项目不存在.png" alt="">
      </div>
      <div class="notfound-font">
        <h2>{{title}}</h2>
        <ul>
          <li>您可以浏览其它相似的其他页面
            <a href="javascript:void(0)" @click="$router.push('/'+type)" > 点这里 </a>
          </li>
          <!-- <li>或者您可以查看 <a href="#">已投标</a></li> -->
        </ul>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>

export default {
  data(){
    return {
      title: '抱歉！ 这个页面已被宇宙人劫持啦！',
      type: ''
    }
  },
  created() {
    this.loadTitle()
  },
  methods:{
    loadTitle(){
      var current_url = window.location.pathname
      if (typeof current_url != 'undefined' && current_url != '') {
        if( current_url.indexOf( 'task' ) !== -1 ){
          this.type = 'task'
        }
        if( current_url.indexOf( 'service' ) !== -1 ){
          this.type = 'service'
        }
        if( current_url.indexOf( 'freelancer' ) !== -1 ){
          this.type = 'freelancer'
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .notfound{
    width: 960px;
    margin: 0 auto;
    display: flex;
    margin-top: 120px;
    img{
      width: 556px;
      height: 352px;
      object-fit: cover;
    }
    .notfound-font{
      margin-left: 85px;
      h2{
        line-height: 30px;
        font-size: 25px;
        margin-bottom: 60px;
        margin-top: 98px;
      }
      ul{
        li{
          font-size: 18px;
          margin-bottom: 20px;
          a{
            color: #00A2E6;
          }
        }
      }
    }
  }
</style>
